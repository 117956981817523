.bcp-theme .ant-table-thead .ant-table-cell {
  background-color: #247f52 !important;
  color: white !important;
}

.bcp-theme .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #247f52;
  border-color: #247f52;
}

.bcp-theme .ant-pagination .ant-pagination-item {
  border-right: 1px solid #247f52 !important;
  border-top: 1px solid #247f52 !important;
  border-bottom: 1px solid #247f52 !important;
}

.bcp-theme .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-right: 1px solid #247f52 !important;
  border-top: 1px solid #247f52 !important;
  border-bottom: 1px solid #247f52 !important;
}

.bcp-theme .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  border: 1px solid #247f52 !important;
}

.bcp-theme .ant-menu .ant-menu-item-selected {
  background-color: #6189cf;
  color: #ffffff;
}

.bcp-theme .ant-detail-building-menu .ant-detail-building-menu-item-selected {
  background-color: #191a1b !important;
  color: #ffffff !important;
}

.bcp-theme .icon-next {
  border-right: 1px solid #247f52 !important;
  border-top: 1px solid #247f52 !important;
  border-bottom: 1px solid #247f52 !important;
}

.bcp-theme .icon-prev {
  border: 1px solid #247f52 !important;
}

.bcp-theme .ant-table-tbody > tr.ant-table-row-deleted td {
  background-color: #a6aaaf !important;
}

.bcp-theme .ant-table-tbody > tr.ant-table-row-normal:hover > td {
  background-color: #f4f8fe !important;
}

.bcp-theme
  .custom-pagination.ant-pagination
  > .ant-pagination-item-active:hover {
  border-left: 1px solid #247f52 !important;
}

.bcp-theme .custom-pagination.ant-pagination > .ant-pagination-item > a {
  color: #247f52;
}

.bcp-theme .custom-menu-detail-building > .ant-menu-item {
  background-color: #0c3823 !important;
  outline: #0c3823 !important;
}

.bcp-theme .custom-menu-detail-building > .ant-menu-item:hover {
  background-color: #2f4a70 !important;
}

.bcp-theme .custom-menu-information > .ant-menu-item,
.bcp-theme
  .custom-menu-information
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  background-color: #0c3823 !important;
}

.bcp-theme .custom-menu-information > .ant-menu-submenu {
  background-color: #0c3823 !important;
}

.bcp-theme
  .custom-menu-information
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item {
  background-color: #17412d !important;
}

.bcp-theme
  .custom-document-menu
  .ant-menu-submenu-open
  .ant-menu-item:not(:last-child) {
  border-bottom: 1px solid #203552 !important;
}

.bcp-theme .custom-document-menu > .ant-menu-item,
.bcp-theme .custom-document-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: #0c3823 !important;
}

.bcp-theme
  .custom-document-menu
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item {
  background-color: #17412d !important;
}

.bcp-theme .radio-custom > .ant-radio-button-wrapper {
  background-color: #021120;
}

.bcp-theme .radio-custom > .ant-radio-button-wrapper-checked {
  background-color: #0c3823 !important;
}

.bcp-theme .folder-list-menu .ant-menu-submenu {
  border-bottom: 1px solid #17412d !important;
}

.bcp-theme .folder-list-menu > .ant-menu-item {
  border-bottom: 1px solid #17412d !important;
}

.bcp-theme .ant-upload-drag {
  border-color: #247f52 !important;
}

.bcp-theme .bordered-menu .ant-menu-submenu-title {
  border-bottom: 1px solid #17412d;
}

.bcp-theme .shared-permission-modal .ant-list-item {
  border-block-end: 1px solid #eeeff2 !important;
}

.bcp-theme .shared-permission-modal .ant-list-item:last-child {
  border-block-end: 1px solid #eeeff2 !important;
}

.bcp-theme
  .custom-pagination
  .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.bcp-theme
  .custom-pagination
  .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
  border-right: 1px solid #247f52 !important;
  border-top: 1px solid #247f52 !important;
  border-bottom: 1px solid #247f52 !important;
}

.bcp-theme .custom-list-area-dropdown .ant-menu-submenu-title {
  background-color: #0c3823 !important;
}

.bcp-theme .custom-list-area-dropdown .ant-menu-item {
  background-color: #17412d;
}

.bcp-theme .custom-list-area-dropdown .ant-menu-item:hover {
  background-color: #17412d !important;
}

.bcp-theme .select-floot-disabled .ant-select-selector {
  background: #f5f5f5 !important;
}

/* update for 532 533 */

.bcp-theme .theme-text-primary {
  color: #247f52;
}

.bcp-theme .theme-bg-primary {
  background: #247f52;
}

.bcp-theme .theme-border-primary {
  border-color: #247f52;
}

.bcp-theme .theme-text-sub-primary {
  color: #a1c6b4;
}

.bcp-theme .theme-bg-sub-primary {
  background: #a1c6b4;
}

.bcp-theme .theme-border-sub-primary {
  border-color: #a1c6b4;
}

.bcp-theme .theme-text-secondary {
  color: #17412d;
}

.bcp-theme .theme-bg-secondary {
  background: #17412d;
}

.bcp-theme .theme-border-secondary {
  border-color: #17412d;
}

.bcp-theme .theme-text-third {
  color: #0c3823;
}

.bcp-theme .theme-bg-third {
  background: #0c3823;
}

.bcp-theme .theme-border-third {
  border-color: #0c3823;
}

.bcp-theme .theme-bg-black-primary {
  background: #222222;
}

.bcp-theme .theme-bg-gradient-primary {
  background: linear-gradient(to right, #247f52, #17412d);
}

.bcp-theme .theme-main-header,
.bcp-theme .theme-viewer-header {
  background: linear-gradient(to right, #247f52, #17412d);
}

.bcp-theme .theme-detail-building-header {
  background: #222222;
}

.bcp-theme .theme-bg-main-layout {
  background-color: #f9fbff;
}

.bcp-theme .ant-btn-primary,
.bcp-theme .ant-dropdown-menu {
  background-color: #247f52 !important;
}

.bcp-custom-menu-hover:hover path {
  fill: #a1c6b4;
}

.bcp-theme .related-point-button {
  border: 1px solid #247f52;
  color: #247f52;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 4px;
}

.bcp-theme .related-point-button:hover {
  cursor: pointer !important;
  color: #247f52CC !important;
  background-color: #ffffff !important;
}

.bcp-theme .ant-btn-primary:hover {
  background-color: #247f52CC !important;
}
