@import '~antd/dist/reset.css';

:where(.css-dev-only-do-not-override-1hfftan)[class*=' ant-form'],
.ant-card {
  font-family: 'Noto Sans JP', sans-serif !important;
}

.ant-pagination .ant-pagination-item-active a {
  color: #ffffff;
}

.ant-card .ant-card-body {
  padding: 60px;
}

.ant-btn.ant-btn-lg {
  height: 60px;
  font-weight: bold;
  font-size: 16px;
}

.custom-label .ant-form-item .ant-form-item-label > label {
  font-size: 16px;
}

.input-floor .ant-form-item-explain-error {
  float: left;
  white-space: pre;
}

.ant-input-lg,
.ant-input-affix-wrapper {
  font-size: 14px !important;
  height: 48px;
}

.ant-btn-default {
  background-color: #a6aaaf;
  color: #fff;
  border: 0;
}

.ant-btn-default:hover {
  color: #fff !important;
  opacity: 0.8;
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  padding: 0 !important;
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 0;
  margin-top: -4px;
  width: 160px;
  padding: 0;
}

.custom-user-dropdown .ant-dropdown-menu-item {
  padding: 11px 15px !important;
  line-height: 18px !important;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0;
}

.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0;
}

.ant-table-wrapper .ant-table-column-sorters {
  display: inline-flex;
}

.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.ant-table-wrapper
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-inline-end: 0;
}

.ant-pagination .ant-pagination-item {
  border-radius: 0;
  margin-inline-end: 0;
}

.ant-pagination .ant-pagination-prev {
  margin-inline-end: 0;
}

.ant-pagination .ant-pagination-prev {
  border-radius: 0;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  border-radius: 0;
}

.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 0;
}

.icon-next {
  height: 32px;
  width: 30px;
  border-radius: 0 10% 10% 0;
}

.icon-prev {
  height: 32px;
  width: 32px;
  border-radius: 10% 0 0 10%;
}

.ant-select-selector.custom-dropdown > .ant-select-selector {
  height: 46px !important;
  display: flex;
  align-items: center;
}

.ant-form-item-label-left.custom-label-selected {
  display: flex;
  align-items: center;
}

.ant-form-item-label-left > label,
.ant-form-item-label-left > label > div > div {
  font-weight: 500;
}

.label-custom-select > .ant-form-item-row > .ant-form-item-label {
  display: flex;
  align-items: center;
}

.thin-light-placeholder
  > .ant-form-item-row
  > .ant-form-item-control
  .ant-input::placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #a6aaaf;
}

.thin-light-placeholder
  > .ant-form-item-row
  > .ant-form-item-control
  .ant-select-selection-placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #a6aaaf;
}

.thin-light-placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #a6aaaf;
}

.thin-placeholder
  > .ant-form-item-row
  > .ant-form-item-control
  .ant-select-selection-placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #787b82;
}

.ant-form > hr {
  border-color: #eeeff240 !important;
  border-style: inset;
  border-width: 0.25px;
}

.ant-form-item-label > label > div > .flex > div {
  color: #b80808 !important;
  font-weight: 500;
}

.sidebar .ant-menu .ant-menu-item {
  border-radius: 0px;
}

.custom-pagination.ant-pagination > .ant-pagination-item-active:hover {
  border: 1px solid transparent !important;
}

.custom-pagination.ant-pagination
  > .ant-pagination-item.ant-pagination-item-active
  > a {
  font-weight: 700 !important;
  color: #fff !important;
}

.custom-pagination.ant-pagination > .ant-pagination-item,
.custom-pagination.ant-pagination > .ant-pagination-prev,
.custom-pagination.ant-pagination > .ant-pagination-next,
.custom-pagination.ant-pagination > .ant-pagination-prev > .icon-prev,
.custom-pagination.ant-pagination > .ant-pagination-next > .icon-next {
  width: 38px;
  height: 38px;
  line-height: 0;
}

.custom-pagination.ant-pagination > .ant-pagination-item > a {
  font-size: 16px;
  font-weight: 400;
  width: 38px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  display: flex;
  align-items: center;
}

.modal-organization .ant-modal-content {
  padding: 40px 60px;
  border-radius: 20px;
}

.modal-invite-user .ant-modal-content {
  width: 538px;
  height: 416px;
  padding: 40px 60px;
  display: flex;
  border-radius: 20px;
}

.input-invite-user .ant-form-item-row .ant-form-item-label {
  display: flex;
}

.input-invite-user .ant-form-item-row .ant-form-item-label > label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #021120;
  margin-top: 4px;
}

.input-invite-user.add-building .ant-form-item-row .ant-form-item-label {
  font-weight: 700;
}

.input-invite-user.add-building .ant-form-item-explain-error {
  width: 300px;
  float: right;
  white-space: pre;
}

.input-invite-user .ant-form-item-control .ant-select-selector {
  font-size: 14px;
  font-weight: 400;
}

.input-floor .ant-form-item-row .ant-form-item-label > label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #021120;
  margin-right: 25px;
}

.input-floor .ant-form-item-explain-error {
  float: left;
  white-space: pre;
}

.input-edit-information .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.input-edit-information .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.input-information .ant-form-item-row .ant-form-item-label > label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #021120;
}

.table-list-org-invite
  .ant-table-wrapper
  .ant-spin-nested-loading
  .ant-spin-container
  .ant-table.ant-table-bordered
  .ant-table-container
  .ant-table-header {
  border-radius: 0px !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.modal-add-building .ant-modal-content {
  width: 591px;
  height: 587.29px;
  padding: 40px 80px;
  display: flex;
  border-radius: 20px;
  gap: 10px;
}

.custom-menu-item > .ant-dropdown-menu {
  padding: 0;
}

.dropdown-custom > .ant-dropdown-menu {
  width: auto;
  border-radius: 4px;
  padding: 6px 0px 6px 4px;
}

.dropdown-custom > .ant-dropdown-menu > .ant-dropdown-menu-item {
  padding: 10px;
}

.dropdown-custom-delete-point > .ant-dropdown-menu {
  width: auto;
  border-radius: 0 4px 4px 0;
}

.dropdown-custom-delete-point > .ant-dropdown-menu > .ant-dropdown-menu-item {
  padding: 8px 12px;
}

.custom-menu-detail-building > .ant-menu-item {
  width: 240px;
  color: #ffffff;
  padding: 7px 0px 7px 10px !important;
  margin: 0;
  border-radius: 0;
}

.custom-menu-information > .ant-menu-item,
.custom-menu-information > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 36px;
  width: 240px;
  color: #ffffff;
  padding: 8px 10px 8px 0px !important;
  margin: 0;
  border-radius: 0;
}

.custom-menu-information > .ant-menu-submenu {
  color: #ffffff;
  border-radius: 0;
  margin: 0;
}

.custom-menu-information > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0;
}

.custom-menu-information
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  width: 0;
}

.custom-menu-information > .ant-menu-submenu > .ant-menu > .ant-menu-item {
  height: fit-content;
  width: 240px;
  color: #ffffff;
  margin: 0;
  border-radius: 0;
  padding: 10px 16px;
}

.custom-menu-information
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item
  > .ant-menu-title-content {
  margin-left: 16px;
}

.custom-document-menu > .ant-menu-submenu {
  border-radius: 0;
}

.custom-document-menu > .ant-menu-item,
.custom-document-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 36px;
  width: 100%;
  color: #ffffff;
  padding: 8px 6px 8px 12px !important;
  margin: 0;
  border-radius: 0;
}

.custom-document-menu > .ant-menu-submenu > .ant-menu > .ant-menu-item {
  height: 36px;
  width: 100%;
  color: #ffffff;
  margin: 0;
  border-radius: 0;
}

.custom-document-menu
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item
  > .ant-menu-title-content {
  margin-left: 22px;
  font-size: 12px;
}

.modal-custom .ant-modal-content {
  padding: 50px 60px 50px 60px;
  border-radius: 20px;
}

.modal-custom.model-custom-default-img .ant-modal-content {
  padding-bottom: 30px !important;
}

.radio-custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
}

.radio-custom > .ant-radio-button-wrapper {
  border-radius: 0px;
  border-width: 0;
  color: #ffffff;
  font-size: 12px;
  justify-content: center;
  display: flex;
  width: 100%;
  font-weight: 700;
  letter-spacing: 2px;
}

.radio-custom > .ant-radio-button-wrapper-checked::before {
  display: none;
}

.radio-custom > .ant-radio-button-wrapper::before {
  display: none;
}

.thin-error-message .ant-form-item-explain-error {
  position: absolute;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.custom-error-message .ant-form-item-explain-error {
  position: absolute;
  white-space: wrap;
  font-size: 14px;
  font-weight: 500;
}

.ant-select-auto-complete .ant-select-selection-search-input {
  font-size: 14px;
  font-weight: 500;
}

.ant-select-auto-complete .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.sm-text-table .ant-table-cell {
  font-size: 14px;
  font-weight: 500;
}

.thumbnail-upload .ant-form-item-label {
  display: flex;
  align-items: center;
}

.thumbnail-upload .ant-form-item-explain-error {
  position: absolute;
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.edit-building-modal .ant-modal-content {
  padding-inline: 0;
  padding-top: 40px;
  padding-bottom: 0;
}

.edit-building-modal .ant-form-item {
  margin-bottom: 20px;
}

.edit-building-modal .ant-picker-input input {
  font-size: 14px;
  line-height: 20px;
  color: #021120;
}

.ant-upload-drag {
  border-radius: 8px;
  cursor: default !important;
}

.rounded-modal-lg .ant-modal-content {
  border-radius: 20px;
}

.custom-select-in-shared-building .ant-select-selector {
  width: 590px;
  padding-inline-end: 115px !important;
}

.custom-select-in-shared-building .ant-select-selection-overflow {
  overflow-y: auto;
  max-height: 100px;
}

.shared-permission-modal .ant-table-header {
  border-radius: 0px !important;
}

.shared-permission-modal .ant-table-body {
  font-size: 14px;
}

.shared-permission-modal .ant-select-selector {
  font-size: 14px;
}

.shared-permission-modal .ant-modal-content {
  padding: 40px 60px 20px 60px;
  border-radius: 20px;
}

.shared-permission-modal .ant-table-cell::before {
  display: none;
}

.shared-permission-modal .ant-select-selection-item {
  font-size: 14px;
  line-height: 28px !important;
  color: #021120;
}

.over-flow-select-box .ant-select-selector {
  max-height: 100px;
  overflow: auto;
  min-height: 40px;
  padding-inline-end: 100px !important;
}

.over-flow-select-box .ant-select-selection-item-content {
  display: flex;
  align-items: center;
}

.custom-label-select-shared-building > .ant-select-selector {
  padding: 0 !important;
}

.error-email-select .ant-select-selection-item-content {
  color: #b80808;
}

.no-style-upload .ant-upload {
  border: none;
  background: transparent;
  padding: 0 !important;
}

.area-map-container {
  width: 100% !important;
  height: 100% !important;
}

.is-link-mode .delete-able-link {
  cursor:
    url('/public/icon/line_delete_icon.svg') 10 10,
    auto;
}

.custom-pagination
  .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.custom-pagination
  .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
  width: 38px;
  height: 38px;
  margin-inline-end: 0px;
  border-radius: 0px;
}

.custom-list-area-dropdown .ant-menu-submenu-title {
  height: 36px;
  width: 100%;
  max-width: 240px;
  color: #ffffff;
  padding: 8px 10px !important;
  margin: 0;
  border-radius: 0;
  gap: 5px;
}

.custom-list-area-dropdown .ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  max-width: 240px;
  border-radius: 0;
  min-height: 36px;
  height: auto;
  display: flex;
}

.annotation-detail-modal .ant-modal-content {
  padding: 30px 0;
}

.custom-page-jump input {
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  padding-inline: 0 !important;
}

.leaflet-grab {
  cursor: default;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #d9d9d9;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border: 2px solid #d9d9d9;
  background: #787b82;
  border-radius: 10px;
}

.paddingless-modal .ant-modal-content {
  padding: 0;
}

.quill-custom-editor > .ql-blank:focus::before {
  content: '';
}

.quill-custom-editor > .ql-tooltip {
  left: 0 !important;
  transform: none !important;
}

.quill-custom-editor > .ql-tooltip::before {
  content: 'リンクを挿入:' !important;
}

.quill-custom-editor > .ql-tooltip > .ql-action::after {
  content: '保存' !important;
}

.ant-table-container {
  border: 0 !important;
}

@media (max-width: 559px) {
  #iframe-responsive {
    width: 100%;
    margin-inline: 5vw;
  }
}

@media (min-width: 560px) and (max-width: 959px) {
  #iframe-responsive {
    width: 100%;
    margin-inline: 10vw;
  }
}

@media (min-width: 960px) {
  #iframe-responsive {
    margin-inline: auto;
    width: 80vw;
    max-width: 1200px;
  }
}
