.tour-theme .ant-table-thead .ant-table-cell {
  background-color: #5f9ea0 !important;
  color: white !important;
}

.tour-theme .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5f9ea0;
  border-color: #5f9ea0;
}

.tour-theme .ant-pagination .ant-pagination-item {
  border-right: 1px solid #5f9ea0 !important;
  border-top: 1px solid #5f9ea0 !important;
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .ant-pagination .ant-pagination-item-active {
  background-color: #5f9ea0;
  border-color: #5f9ea0;
}

.tour-theme .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-right: 1px solid #5f9ea0 !important;
  border-top: 1px solid #5f9ea0 !important;
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  border: 1px solid #5f9ea0 !important;
}

.tour-theme .ant-menu .ant-menu-item-selected {
  background-color: #6189cf;
  color: #ffffff;
}

.tour-theme .ant-detail-building-menu .ant-detail-building-menu-item-selected {
  background-color: #191a1b !important;
  color: #ffffff !important;
}

.tour-theme .icon-next {
  border-right: 1px solid #5f9ea0 !important;
  border-top: 1px solid #5f9ea0 !important;
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .icon-prev {
  border: 1px solid #5f9ea0 !important;
}

.tour-theme .ant-table-tbody > tr.ant-table-row-deleted td {
  background-color: #a6aaaf !important;
}

.tour-theme .ant-table-tbody > tr.ant-table-row-normal:hover > td {
  background-color: #f4f8fe !important;
}

.tour-theme
  .custom-pagination.ant-pagination
  > .ant-pagination-item-active:hover {
  border-left: 1px solid #5f9ea0 !important;
}

.tour-theme .custom-pagination.ant-pagination > .ant-pagination-item > a {
  color: #5f9ea0;
}

.tour-theme .custom-menu-detail-building > .ant-menu-item {
  background-color: #152c2f !important;
  outline: #152c2f !important;
}

.tour-theme .custom-menu-detail-building > .ant-menu-item:hover {
  background-color: #2f4a70 !important;
}

.tour-theme .custom-menu-information > .ant-menu-item,
.tour-theme
  .custom-menu-information
  > .ant-menu-submenu
  > .ant-menu-submenu-title {
  background-color: #152c2f !important;
}

.tour-theme .custom-menu-information > .ant-menu-submenu {
  background-color: #152c2f !important;
}

.tour-theme
  .custom-menu-information
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item {
  background-color: #152c2f !important;
}

.tour-theme
  .custom-document-menu
  .ant-menu-submenu-open
  .ant-menu-item:not(:last-child) {
  border-bottom: 1px solid #203552 !important;
}

.tour-theme .custom-document-menu > .ant-menu-item,
.tour-theme .custom-document-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: #152c2f !important;
}

.tour-theme
  .custom-document-menu
  > .ant-menu-submenu
  > .ant-menu
  > .ant-menu-item {
  background-color: #152c2f !important;
}

.tour-theme .radio-custom > .ant-radio-button-wrapper {
  background-color: #021120;
}

.tour-theme .radio-custom > .ant-radio-button-wrapper-checked {
  background-color: #152c2f !important;
}

.tour-theme .folder-list-menu .ant-menu-submenu {
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .folder-list-menu > .ant-menu-item {
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .ant-upload-drag {
  border-color: #5f9ea0 !important;
}

.tour-theme .bordered-menu .ant-menu-submenu-title {
  border-bottom: 1px solid #5f9ea0;
}

.tour-theme .shared-permission-modal .ant-list-item {
  border-block-end: 1px solid #eeeff2 !important;
}

.tour-theme .shared-permission-modal .ant-list-item:last-child {
  border-block-end: 1px solid #eeeff2 !important;
}

.tour-theme
  .custom-pagination
  .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.tour-theme
  .custom-pagination
  .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
  border-right: 1px solid #5f9ea0 !important;
  border-top: 1px solid #5f9ea0 !important;
  border-bottom: 1px solid #5f9ea0 !important;
}

.tour-theme .custom-list-area-dropdown .ant-menu-submenu-title {
  background-color: #5f9ea0 !important;
}

.tour-theme .custom-list-area-dropdown .ant-menu-item {
  background-color: #5f9ea0;
}

.tour-theme .custom-list-area-dropdown .ant-menu-item:hover {
  background-color: #5f9ea0 !important;
}

.tour-theme .select-floot-disabled .ant-select-selector {
  background: #f5f5f5 !important;
}

/* update for 532 533 */

.tour-theme .theme-text-primary {
  color: #5f9ea0;
}

.tour-theme .theme-bg-primary {
  background: #5f9ea0;
}

.tour-theme .theme-border-primary {
  border-color: #5f9ea0;
}

.tour-theme .theme-text-sub-primary {
  color: #91dce5;
}

.tour-theme .theme-bg-sub-primary {
  background: #91dce5;
}

.tour-theme .theme-border-sub-primary {
  border-color: #91dce5;
}

.tour-theme .theme-text-secondary {
  color: #5f9ea0;
}

.tour-theme .theme-bg-secondary {
  background: #5f9ea0;
}

.tour-theme .theme-border-secondary {
  border-color: #5f9ea0;
}

.tour-theme .theme-text-third {
  color: #152c2f;
}

.tour-theme .theme-bg-third {
  background: #152c2f;
}

.tour-theme .theme-border-third {
  border-color: #152c2f;
}

.tour-theme .theme-bg-black-primary {
  background: #5f9ea0;
}

.tour-theme .theme-bg-gradient-primary {
  background: linear-gradient(to right, #3eb1ad, #1a4b49);
}

.tour-theme .theme-main-header,
.tour-theme .theme-viewer-header,
.tour-theme .theme-detail-building-header {
  background: linear-gradient(to right, #3eb1ad, #1a4b49);
}

.tour-theme .ant-btn-primary,
.tour-theme .ant-dropdown-menu {
  background-color: #3eb1ad !important;
}

.tour-theme .theme-bg-main-layout {
  background-color: #b3cfcb;
}

.tour-theme .related-point-button {
  border: 1px solid #5f9ea0;
  color: #5f9ea0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 4px;
}

.tour-theme .related-point-button:hover {
  cursor: pointer !important;
  color: #5f9ea0CC !important;
  background-color: #ffffff !important;
}

.tour-theme .ant-btn-primary:hover {
  background-color: #5f9ea0CC !important;
}
