@tailwind base;
@tailwind components;
@tailwind utilities;

body,
div,
span,
article,
a {
  font-family:
    Noto Sans JP,
    sans-serif !important;
}

body {
  color: #021120 !important;
}
